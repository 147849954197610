import {
    NUMERICS_KEYS,
    LITERAL_KEYS,
    DEFAULT_PRESETS,
    INITIAL_STATE,
    FILTERS_INITIAL_STATE
} from "../../helpers/constants";
import { NO_KEY } from "../../helpers/constants/keys";
import getExtName from "../../helpers/utils/getExtName";
import { v4 as uuidv4 } from 'uuid';
function isNotVideo(track) {
    return getExtName(track.fileName) !== "mp4";
}

function normalizeTracksList(list, choosenTrack, type, VersionsData) {
    return list.map(track => {
        const {
            artist,
            artiste,
            bpm,
            cover,
            createdAt,
            genre,
            id,
            key,
            kind,
            rank,
            rating,
            presetId,
            songName,
            subversionType,
            trackVersions,
            uiId,
            name,
            tracks,
            isAlbumTrackSearch,
            preset,
            updatedAt,
            album_id,
            mood
        } = track;
        const uniqueUid = uuidv4();
        let versionId = null;
        let trackKey = key || findKeyForTrack(trackVersions);
        if (choosenTrack.track && choosenTrack.track.id === id) {
            versionId = choosenTrack.track.versionId;
        } else {
            trackVersions.sort((x, y) => {
                return y.versionType !== "Clean" ? -1 : 1;
            });
            versionId = trackVersions[0] && trackVersions[0].id;
        }

        const filteredTrackVersions =
            type === "audio" ? trackVersions.filter(isNotVideo) : trackVersions;

        return {
            artist: artiste || artist,
            bpm,
            cover,
            id,
            genre,
            key: getNumericKey(trackKey),
            name: (typeof songName === 'undefined' && name) ? name : songName,
            rating,
            rank,
            released: (updatedAt && updatedAt !== null) ? updatedAt.replace(".000Z", "") : createdAt && createdAt.replace(".000Z", ""),
            presetId,
            preset,
            subversionType,
            type: kind,
            uiId,
            tracks,
            isAlbumTrackSearch,
            album_id,
            uniqueUid,
            mood,
            versions: filteredTrackVersions.map(elem => {
                const name =
                    elem.versionType === "Main"
                        ? elem.additionalVersions &&
                            elem.additionalVersions === "Instrumental"
                            ? elem.additionalVersions
                            : elem.versionType
                        : elem.versionType;
                return {
                    key: getNumericKey(elem.key),
                    id: elem.id,
                    tag: elem.tag,
                    presetId: elem.presetId,
                    released: elem.created && elem.created.replace(".000Z", ""),
                    name,
                    genreName:elem.genreName,
                    presetName:elem.presetName,
                    mood:elem.mood,
                    subname: elem.subversionType,
                    bpm: elem.bpm,
                    additionalVersions: elem.additionalVersions,
                    nameToDisplay: getNameToDisplay(elem, track, VersionsData),
                    link: elem.source,
                    fileName: elem.fileName,
                    downloaded: elem.downloaded,
                    downloadDate: elem.downloadDate,
                    trackId: elem.trackId,
                    isCuratedSetMember: elem.isCuratedSetMember,
                    isHightlighted: elem.isHightlighted
                };
            }),
            versionId
        };
    });
}

function getNumericKey(key) {
    return NUMERICS_KEYS[key] || NO_KEY;
}

function findKeyForTrack(versions) {
    if (!versions || versions.length === 0) return null;
    const suitableVersions = {};
    versions.forEach(({ versionType, subversionType, key }) => {
        if (
            versionType === "Main" &&
            !subversionType &&
            !suitableVersions.main &&
            key && key !== "No key"
        ) {
            suitableVersions.main = key;
        }

        if (
            versionType === "Clean" &&
            !subversionType &&
            !suitableVersions.clean &&
            key && key !== "No key"
        ) {
            suitableVersions.clean = key;
        }

        if (!suitableVersions.any && key && key !== "No key") {
            suitableVersions.any = key;
        }
    });
    return (
        suitableVersions.main || suitableVersions.clean || suitableVersions.any
    );
}

function getNameToDisplay(
    {
        versionType,
        subversionType,
        additionalVersions,
        presetId: versionPresetId,
        created,
        fileName
    },
    { songName, id },
    VersionsData
) {
    let result = fileName.split('-').pop().replace(/\.[^.]+$/, '').trim();

    /*let isAfterTargetDate  = new Date(created) > new Date("2023-09-20");
    // console.log(VersionsData, new Date(created),new Date("2023-09-20"), new Date(created) > new Date("2023-09-20"), 'VersionsData----------3'+id);
    if(isAfterTargetDate){
        if (versionType) {
            let currentVersion = VersionsData.filter((v) => {
                return v.name.trim() === versionType.trim();
            });
            currentVersion = currentVersion.length > 0 ? currentVersion[0] : null;
            console.log(VersionsData, currentVersion, 'VersionsData----------4'+id);
            result += ` ${currentVersion.displayName}`;
        }
    }else{
        if (additionalVersions && additionalVersions.length > 0) {
            additionalVersions.split("&&&").forEach(version => {
                result += ` (${version})`;
            });
        }

        if (subversionType) {
            result += ` (${subversionType})`;
        }

        if (versionType) {
            result += ` (${versionType})`;
        }
    }*/
    return result;
}

function getRequestTracksUrlParameters(params, state) {
    if (params.sortField && params.sortField === "rank") {
        params.type = "audio";
        params.searchQuery = "";
    }
    const requestParams = { ...state, ...params };
    const {
        order,
        sortField,
        searchQuery,
        type,
        artistId,
        presetName,
        typeParams,
        bannerRequest
    } = requestParams;
    const genre = (typeParams.genres) ? typeParams.genres : '';
    const country = (typeParams.country && typeParams.country !== '') ? typeParams.country : '';
    const dateRange = (typeParams.dateRange && typeParams.dateRange !== '') ? typeParams.dateRange : '';
    const { versions, genres, keys, bpm } = normalizePresetsParams(
        requestParams
    );
    let stringifyGenres = '';
    if (genre !== '') {
        stringifyGenres = genre && (encodeURIComponent(genre))
    } else {
        stringifyGenres = genres && genres.map(str => encodeURIComponent(str))
    }
    const offset = params.offset || state[type].offset;
    const limit = params.limit || state[type].limit;

    const isBannerRequest = sortField === 'createdAt' && type === "audio" && bannerRequest && bannerRequest !== false && bannerRequest > 0 &&
    (!presetName || (presetName && presetName === "All")) &&
    searchQuery.length === 0 &&
    stringifyGenres.length === 0 &&
    (!versions || (versions && versions.length === 0)) &&
    (!keys || (keys && keys.length === 0)) &&
    !country &&
    !dateRange &&
    !artistId;

    const result =
        `preset=${type === "video" ? "All" : presetName}&` +
        `limit=${limit}&` +
        `offset=${offset}&` +
        `order=${order}&` +
        `bannerRequest=${isBannerRequest ? bannerRequest : false}&` +
        `sortField=${sortField}&` +
        `searchQuery=${searchQuery ? searchQuery.replace("&", "") : ""}&` +
        `type=${type}&` +
        `bpm=${JSON.stringify(bpm)}&` +
        `versions=${JSON.stringify(
            versions && versions.map(str => encodeURIComponent(str))
        )}&` +
        `genres=${stringifyGenres}&` +
        `keys=${JSON.stringify(
            keys &&
            keys
                .map(str =>
                    LITERAL_KEYS[str].map(key => encodeURIComponent(key))
                )
                .flat()
        )}&` +
        `status=A&` +
        `country=${country}&` +
        `dateRange=${dateRange}&` +
        `artistId=${artistId}`;
    return result;
}

function normalizePresetsParams({ bpm, versions, genres, keys, presetName }) {

    let result = { bpm, versions, genres, keys, name: presetName };

    const preset = DEFAULT_PRESETS.find(
        defaultPreset => presetName === defaultPreset.name
    );
    if (presetName && presetName !== "All" && isEquals(preset, result)) {
        result = {};
    }
    return result;
}

export const isEqual = (preset, filters) => {
    if (!preset || !filters) return false;
    const {
        bpm: presetBpm,
        genres: presetGenres,
        versions: presetVersions
    } = preset;
    const {
        bpm: filterBpm,
        genres: filterGenres,
        versions: filterVersions
    } = filters;
    return (
        preset.name === filters.name &&
        compareFilterParams(presetBpm || [], filterBpm) &&
        compareFilterParams(presetGenres || [], filterGenres) &&
        compareFilterParams(presetVersions || [], filterVersions)
    );
};

export const isEquals = (preset, filters) => {
    if (!preset || !filters) return false;
    const {
        filter: {
            bpm: presetBpm,
            genres: presetGenres,
            versions: presetVersions
        }
    } = preset;
    const {
        bpm: filterBpm,
        genres: filterGenres,
        versions: filterVersions
    } = filters;
    return (
        preset.name === filters.name &&
        compareFilterParams(presetBpm || [0, 256], filterBpm) &&
        compareFilterParams(presetGenres || [], filterGenres) &&
        compareFilterParams(presetVersions || [], filterVersions)
    );
};

function compareFilterParams(a, b) {
    return (
        (!a && !b) ||
        (a && b && JSON.stringify(a.sort()) === JSON.stringify(b.sort()))
    );
}

function normalizePresets(presets) {
    const result = [];
    if (presets && presets.presets) {
        const rawPresetsList = presets.presets.split(";");
        rawPresetsList.forEach(preset => {
            if (preset.includes("=1")) {
                const cleanPreset = preset.replace("=1", "");
                result.push(cleanPreset);
            }
        });
    }
    return result;
}

function createFuncObject(comp) {
    return {
        addActions: comp.addActions,
        clearMedia: comp.clearMedia,
        downloadMediaList: comp.downloadMediaList,
        downloadMedia: comp.downloadMedia,
        fetchSplashVideo: comp.fetchSplashVideo,
        getArtist: comp.getArtist,
        getLocation: comp.getLocation,
        getArtistVideo: comp.getArtistVideo,
        getDownloads: comp.getDownloads,
        pausePlaying: comp.pausePlaying,
        resetDisplayOfTrackVersions: comp.resetDisplayOfTrackVersions,
        requestTracks: comp.requestTracks,
        requestArtists: comp.requestArtists,
        requestActualArtists: comp.requestActualArtists,
        requestSpotlight: comp.requestSpotlight,
        requestGallery: comp.requestGallery,
        requestDownloadTracks: comp.requestDownloadTracks,
        resetQuery: comp.resetQuery,
        resetSearchQuery: comp.resetSearchQuery,
        setDisplayOfTrackVersions: comp.setDisplayOfTrackVersions,
        hideDisplayOfTrackVersions: comp.hideDisplayOfTrackVersions,
        resetAudioFilters: comp.resetAudioFilters,
        setArtist: comp.setArtist,
        setRating: comp.setRating,
        setMediaQuery: comp.setMediaQuery,
        startPlaying: comp.startPlaying,
        setPlayingTrack: comp.setPlayingTrack,
        setChoosenTrack: comp.setChoosenTrack,
        setVersion: comp.setVersion,
        stopPlaying: comp.stopPlaying,
        setAvatar: comp.setAvatar,
        setPreset: comp.setPreset,
        setDownloads: comp.setDownloads,
        setScrollbar: comp.setScrollbar,
        setSearchQuery: comp.setSearchQuery,
        setSortField: comp.setSortField,
        resetTrackLimiting: comp.resetTrackLimiting,
        getTrackLink: comp.getTrackLink,
        setRequestContentMethod: comp.setRequestContentMethod,
        requestTracksWithRetry: comp.requestTracksWithRetry,
        fetchDjTransactionHistory: comp.fetchDjTransactionHistory,
        fetchPresetsWithFilters: comp.fetchPresetsWithFilters,
        fetchCountries: comp.fetchCountries,
        fetchBanners: comp.fetchBanners,
        setBannerRequest: comp.setBannerRequest,
        resetBannerRequest: comp.resetBannerRequest
    };
}

async function resetList(comp) {
    await comp.setState(FILTERS_INITIAL_STATE);
}

export {
    FILTERS_INITIAL_STATE,
    INITIAL_STATE,
    DEFAULT_PRESETS,
    LITERAL_KEYS,
    NUMERICS_KEYS,
    getRequestTracksUrlParameters,
    normalizeTracksList,
    createFuncObject,
    resetList,
    normalizePresets
};
