import React from "react";
import { AuthContext } from "./AuthContext";
import PropTypes from "prop-types";
import _ from "lodash";

import makeHttpRequest from "sources/api/http/makeHttpRequest";
import { getYoutubeLocalAddress } from "../helpers/utils/getVideoAddress";
import { isCuratedSets } from "helpers/checkPathname";
import t from "../helpers/getText";
import { withRetryHandling } from "../sources/api/utils/withRetryHandling";

import {
    createFuncObject,
    FILTERS_INITIAL_STATE,
    getRequestTracksUrlParameters,
    INITIAL_STATE,
    normalizePresets,
    normalizeTracksList,
    resetList
} from "./helpers/contentContextHelper";
import { withCommonDialogContext } from "./helpers/withCommonDialogContext";
import { COMMON_DIALOGS } from "./CommonDialogContext";

const ContentContext = React.createContext("content");

class ContextContainer extends React.Component {
    static propTypes = {
        authValue: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.funcs = createFuncObject(this);
        this.location = {};
        this.requestContentMethod = null;
        window.cc = this;
    }

    tracksRequest = null;

    componentDidMount() {
        // console.log("componten did mounttttttttttttttttttttttttttttttttttttttttttttttttttttt")
        this.fetchGenres();
        // this.fetchDjTransactionHistory();
        this.getPreviewTimeInSec();
        //this.fetchPresetsWithFilters();
    }

    setScrollbar = ref => {
        this.setState({ scrollbar: ref });
    };

    setSearchQuery = searchQuery => {
        this.setState({ searchQuery });
    };

    resetBannerRequest = () => {
        this.setState({ bannerRequest: false });
    };

    setBannerRequest = bannerRequest => {
        this.resetQuery();
        this.setState({ bannerRequest });
    };

    setArtist = newArtistProps => {
        this.setState({ ...newArtistProps });
    };

    getTrackLink = async trackVersionId => {
        const { makeAuthenticateHttpRequest } = this.props.authValue;

        return makeAuthenticateHttpRequest({
            method: "get",
            path: `/tracks/signedLink/${trackVersionId}`
        });
    };

    getLocation = async () => {
        if (!this.location.allowed && !this.location.blocked) {
            try {
                const {
                    coords: { latitude, longitude }
                } = await new Promise((res, rej) => {
                    navigator.geolocation.getCurrentPosition(res, rej);
                });
                this.location = { allowed: true, latitude, longitude };
            } catch (e) {
                this.location.blocked = true;
            }
        }
        return this.location;
    };

    setRequestContentMethod = method => {
        this.requestContentMethod = method;
    };

    setDisplayOfTrackVersions = ({ displayType, trackId, uniqueUid = false }) => {
        const { displayVersions } = this.state.audio;
        if (
            displayVersions &&
            displayVersions.id === trackId &&
            displayVersions.uniqueUid === uniqueUid &&
            displayVersions.displayType === displayType
        ) {
            this.setState({
                audio: { ...this.state.audio, displayVersions: null }
            });
        } else {
            this.setState({
                audio: {
                    ...this.state.audio,
                    displayVersions: { id: trackId, displayType, uniqueUid  }
                }
            });
        }
    };

    hideDisplayOfTrackVersions = () => {
        this.setState({
            audio: { ...this.state.audio, displayVersions: null }
        });
    };

    clearMedia = () => {
        const { video, audio } = FILTERS_INITIAL_STATE;
        this.setState({ video, audio });
    };

    setSortField = value => {
        this.setState({ currentSortField: value });
    };

    resetDisplayOfTrackVersions = () => {
        const { audio } = this.state;
        this.setState({ audio: { ...audio, displayVersions: null } });
    };

    fetchGenres = async () => {
        try {
            const {
                genres: allGenres,
                versions: allVersions,
                VersionsData
            } = await makeHttpRequest({
                method: "get",
                path: "/tracks/genres"
            });
            this.setState({ allGenres, allVersions, VersionsData });
        } catch (error) {
            console.log(error);
        }
    };
    
    fetchPresetsWithFilters = async () => {
        try {
            const {
                presets
            } = await makeHttpRequest({
                method: "get",
                path: "/presets/fetchPresetsWithFilters"
            });
            this.setState({ presets });
        } catch (error) {
            console.log(error);
        }
    };

    fetchDjTransactionHistory = async (data) => {
        try {
            this.setState({ requestBillingProcessing: true });
            const result = await makeHttpRequest({
                method: "get",
                path: this.getPathForDJUserTransHistory(data)
            });
            this.setState({
                result:result,
                requestBillingProcessing: false,
                offset: data.offset
            });
        } catch (error) {
            console.log(error);
        }
    };
    

    fetchCountries = async () => {
        try {
            this.setState({ requestFetchCountriesProcessing: true });
            const result = await makeHttpRequest({
                method: "get",
                path: this.getPathForFetchCountries()
            });
            this.setState({
                countries:result,
                requestFetchCountriesProcessing: false
            });
        } catch (error) {
            console.log(error);
        }
    };

    getPathForFetchCountries() {
    
        let path = `/countries`;
          
        return path;
    }

    getPathForDJUserTransHistory(data) {
        const {
            offset,
            limit,
            id,
            // transactionId,
            // type,
            startDate,
            endDate,
            // transactionStatus,
            // invoiceNumber,
            // lastFourCardDigit,
            // amount,
            // subscriptionId,
            userId
        } = data;
    
        let path = `/admins/getDJTransHistory/?limit=${Number.parseInt(
            limit
        )}&offset=${Number.parseInt(offset)}`;
        if (id) {
            path += `&id=${id}`;
        }
        if (userId) {
            path += `&userId=${userId}`;
        }
        if (startDate) {
            path += `&startDate=${startDate}`;
        }
        if (endDate) {
            path += `&endDate=${endDate}`;
        }
    
        return path;
    }

    getPreviewTimeInSec = async () => {
        try {
            const previewTimeInSec = await makeHttpRequest({
                method: "get",
                path: "/tracks/previewTimeInSec"
            });
            this.setState({ previewTimeInSec });
        } catch (error) {
            console.log(error);
        }
    };

    setRating = async ({ trackId, rating }, callback) => {
        const { makeAuthenticateHttpRequest } = this.props.authValue;
        try {
            await makeAuthenticateHttpRequest({
                method: "post",
                path: "/feedbacks",
                data: { trackId, rating }
            });

            const {
                audio: { list },
                choosenTrack: { track }
            } = this.state;

            if (track && track.id === trackId) {
                track.rating = rating;
            }

            list.forEach(elem => {
                if (elem.id === trackId) {
                    elem.rating = rating;
                }
            });

            this.setState({
                audio: { ...this.state.audio, list },
                choosenTrack: { ...this.state.choosenTrack, track }
            });
            if (callback) callback();
        } catch (error) {
            console.log(error);
        }
    };

    getArtist = async params => {
        try {
            const { id } = params;
            const result = await makeHttpRequest({
                method: "get",
                path: `/artists?limit=1&offset=0&id=${id}&name=''&active=true`
            });
            if (result.count > 0) {
                this.setState({
                    ...FILTERS_INITIAL_STATE,
                    artists: {
                        ...this.state.artists,
                        id,
                        artist: result.list[0]
                    }
                });
            } else {
                this.setState({
                    artists: { ...this.state.artists, id }
                });
            }
            this.requestTracks({
                commonParams: {
                    status: "A",
                    artistId: id,
                    sortField: "createdAt",
                    type: "audio"
                }
            });
            this.getArtistVideo(id);
        } catch (error) {
            console.log(error);
        }
    };

    getArtistVideo = async artistId => {
        const video = await makeHttpRequest({
            method: "get",
            path: `/artists/getVideo?artistId=${artistId}`
        });

        this.setState({
            video: {
                ...this.state.video,
                list: video,
                empty: video.length === 0,
                count: video.length,
                status: "A",
                artistId: ""
            }
        });
    };

    getDownloads = async () => {
        const result = await makeHttpRequest({
            method: "get",
            path: "/Downloads"
        });

        this.setState({ downloadList: result });
    };

    setDownloads = async props => {
        const { type, element = {} } = props;
        const { downloadList } = this.state;
        const thisTrack = downloadList.filter(
            media => media.versionId === element.versionId
        );

        if (type === "deleteAll") {
            this.deleteDownloads();
        } else if (type === "delete" && thisTrack.length) {
            this.deleteDownloads(thisTrack[0].id);
        } else if (type === "add" && !thisTrack.length) {
            this.addDownload(element);
        }
    };

    deleteDownloads = async id => {
        const query = id ? { type: "one", id } : { type: "all" };
        const result = await makeHttpRequest({
            method: "delete",
            path: `/Downloads`,
            data: query
        });
        this.setState({ downloadList: result });
    };

    addDownload = async element => {
        const result = await makeHttpRequest({
            method: "post",
            path: `/Downloads`,
            data: element
        });
        this.setState({ downloadList: result });
    };

    downloadMedia = async media => {
        const {
            mediaType,
            mediaKey,
            mediaName,
            id,
            version,
            versionId,
            artist,
            nameToDisplay,
            onlySaveToDB = false,
            onlyLinkRequired = false
        } = media;

        let isTrackLimited = false;
        console.log(versionId, 'versionId------------1')
        await this.addActions(
            "download",
            [
                {
                    id,
                    version: mediaType === "video" ? "video" : version,
                    versionId
                }
            ],
            countTrackActionsByUser => {
                if (countTrackActionsByUser >= 3) {
                    isTrackLimited = true;
                    const alertMessage = onlySaveToDB
                        ? t("tracks.downloadArchiveLimitedMessage")
                        : t("tracks.downloadTrackLimitedMessage");
                    this.setState({
                        isTrackLimited: isTrackLimited,
                        trackLimitedMessage: alertMessage
                    });
                    if (!onlySaveToDB && !onlyLinkRequired) {
                        this.resetTrackLimiting();
                    }
                    return;
                }

                if (onlyLinkRequired) return;
                if (onlySaveToDB) return;

                document.location.href =
                    `/s3Proxy?fileKey=${encodeURI(
                        mediaKey
                    )}&fileName=${encodeURIComponent(mediaName)}` +
                    `&fileType=${encodeURIComponent(mediaType)}` +
                    `&artist=${encodeURIComponent(artist)}` +
                    `&version=${encodeURIComponent(version)}` +
                    `&nameToDisplay=${encodeURIComponent(nameToDisplay)}`;
            }
        );

        if (onlyLinkRequired) {
            return (
                `${document.location.origin}/s3Proxy?fileKey=${encodeURI(
                    mediaKey
                )}&fileName=${encodeURIComponent(mediaName)}` +
                `&fileType=${encodeURIComponent(mediaType)}` +
                `&artist=${encodeURIComponent(artist)}` +
                `&version=${encodeURIComponent(version)}` +
                `&nameToDisplay=${encodeURIComponent(nameToDisplay)}`
            );
        }

        const {
            audio: { list },
            audio
        } = this.state;

        const newList = list.map(stateTrack => {
            let result = stateTrack;
            if (stateTrack.id === id) {
                stateTrack.versions = stateTrack.versions.map(vers => {
                    let newVers = vers;
                    if (vers.id === versionId) {
                        vers.downloaded = true;
                        vers.downloadDate = new Date();
                        newVers = { ...vers };
                    }
                    return newVers;
                });
                result = { ...stateTrack };
            }
            return result;
        });
        this.setState({ audio: { ...audio, list: newList } });

        return isTrackLimited;
    };

    resetTrackLimiting = () => {
        this.setState({ isTrackLimited: false });
    };

    fetchSplashVideo = async () => {
        const result = await makeHttpRequest({
            method: "get",
            path: `/staticMedias`
        });
        const newResult = result.list.map(mediaFile => {
            if (mediaFile.type === "youtube")
                return {
                    ...mediaFile,
                    link: getYoutubeLocalAddress(mediaFile.link)[0]
                };
            return mediaFile;
        });
        this.setState({
            splash: {
                list: newResult,
                count: result.count
            }
        });
    };

    requestArtists = async params => {
        try {
            this.setState({ requestArtistsProcessing: true });
            const requestParams = { ...this.state.artists, ...params };
            const { limit, offset, id, name, visible } = requestParams;
            const result = await makeHttpRequest({
                method: "get",
                path:
                    `/artists?` +
                    `limit=${limit}&` +
                    `offset=${offset}&` +
                    `id=${id}&` +
                    `name=${name}&` +
                    `active=true&` +
                    `visible=${visible}`
            });
            this.setState({
                artists: {
                    ...this.state.artists,
                    ...params,
                    list: result.list,
                    count: result.count
                },
                requestArtistsProcessing: false
            });
        } catch (error) {
            console.log(error);
            this.setState({ requestArtistsProcessing: false });
        }
    };

    requestActualArtists = async () => {
        try {
            const result = await makeHttpRequest({
                method: "get",
                path: `/artists/getRotationArtists?`
            });
            this.setState({
                artists: {
                    ...this.state.artists,
                    list: result,
                    count: result.length,
                    offset: 0
                }
            });
        } catch (error) {
            console.log(error);
        }
    };


    

    requestSpotlight = async () => {
        try {
            const result = await makeHttpRequest({
                method: "get",
                path: `/artists/busySpotlightArtist?`
            });
            this.setState({
                artists: { ...this.state.artists, spotlight: result }
            });
        } catch (error) {
            console.log(error);
        }
    };

    setTracksByType(type, response) {
        const { choosenTrack, VersionsData } = this.state;
        const prevState = this.state[type];

        const { list: rawList, count, presets } = response;
        // console.log(VersionsData, 'versionsData------------1')
        const list = normalizeTracksList(rawList, choosenTrack, type, VersionsData);
        const newState = {
            ...prevState,
            list,
            count,
            empty: list.length === 0,
            status: "A",
            artistId: ""
        };

        this.setState({
            [type]: newState,
            presets: normalizePresets(presets),
            requestTracksProcessing: false
        });
    }

    setPreset = ({ name }) => {
        this.setState({ presetName: name });
    };

    setStatePromise = async newState => {
        return new Promise(res => this.setState(newState, res));
    };

    requestTracks = async props => {
        const {
            needToResetList = false,
            needToResetPreset = false,
            presetName = this.state.presetName,
            typeParams = {},
            commonParams: rowCommonParams,
            isDownloads
        } = props;

        if (
            isCuratedSets(window.location.pathname) &&
            this.requestContentMethod
        ) {
            this.requestContentMethod(props);
            return;
        }

        const { makeAuthenticateHttpRequest } = this.props.authValue;
        const {
            name: presetForNextRequest,
            filter
        } = this.getPresetAndFilter();

        const commonParams = {
            ...rowCommonParams,
            ...filter,
            presetName: needToResetPreset
                ? FILTERS_INITIAL_STATE.presetName
                : presetName
        };

        const type =
            commonParams.sortField === "rank"
                ? "audio"
                : commonParams.type || this.state.type;

        if (needToResetList) await resetList(this, type);

        await this.setStatePromise({
            [type]: { ...this.state[type], ...typeParams, empty: false },
            ...commonParams,
            requestTracksProcessing: true,
            presetForNextRequest: false
        });

        const resultHandler = result => {
            if (this.tracksRequest === resultHandler) {
                this.setTracksByType(type, result);
            }
        };

        this.tracksRequest = resultHandler;
        const params = {
            commonParams,
            typeParams,
            presetName: encodeURIComponent(
                presetName || presetForNextRequest
            )
        };
        const state = { ...this.state };
        const urlParams = getRequestTracksUrlParameters(
            params,
            state
        );
        const requestPath = isDownloads
            ? `/tracks/downloadTracks?`
            : `/tracks?`;

        const result = await makeHttpRequest({
            method: "get",
            path: requestPath + urlParams
        });
        resultHandler(result);
    };

    requestTracksWithRetry = async (...params) => {
        // console.log(params);
        const handle = withRetryHandling(this.requestTracks);
        return handle(...params).catch(_ => {
            this.props.commonDialogContext.setCurrentDialog(
                COMMON_DIALOGS.ConnectionError,
                () => this.requestTracksWithRetry(...params)
            );
        });
    };

    getPresetAndFilter = () => {
        let { name, genre, filter } = this.state.presetForNextRequest || {};
        if (genre) {
            filter = { ...filter, genres: [genre] };
        }
        return { name, filter };
    };

    requestDownloadTracks = async data => {
        await this.requestTracks({ ...data, isDownloads: true });
    };

    setChoosenTrack = (track, versionId) => {
        this.setState({
            choosenTrack: { track: { ...track, versionId }, isPlaying: true }
        });
    };

    startPlaying = async ({ uiId, versionId, isAlbumTrack = false}) => {
        const { choosenTrack, audio } = this.state;
        const {
            authValue: { user }
        } = this.props;

        if (!user) {
            window.location = "/login";
        }

        let track = null;
        // console.log(choosenTrack, audio,{ uiId, versionId }, 'choosenTrack, audio------startPlaying')
        if (choosenTrack.track && choosenTrack.track.uiId === uiId) {
            track = choosenTrack.track;
            // console.log(track, 'track---if---startPlaying')
        } else if(isAlbumTrack) {
            track = audio.list.find(elem =>
                (elem.tracks) && elem.tracks.find(trk => trk.uiId === uiId)
            );
            // console.log(track, 'track---else-if---startPlaying')
        } else {
            track = audio.list.find(elem => elem.uiId === uiId);
            // console.log(track,typeof audio.list[0].tracks,audio.list[0].tracks,audio.list[0], 'track--else------startPlaying')
        }
        

        /*
            It was decided to remove this constraint (track plays count) for
            the time being. - Uncommented by Anatolii following Peter's request (2020-09-29)
        */
        
        if (
            !choosenTrack.track ||
            (choosenTrack.track && choosenTrack.track.versionId !== versionId)
        ) {
            let list = {versionId};
            if (track) { 
                // Check if the track object itself exists
                // console.log('track exists:', track);
                if (track.id !== undefined) {
                    list.id = track.id;
                } else {
                    console.warn('Track does not have an id property:', track);
                }
            } else {
                track = await audio.list.find(elem =>
                    (elem.tracks) && elem.tracks.find(trk => trk.uiId === uiId) 
                );
                list.id = track.id;
                console.log(list.id, 'track is undefined');
            }

            let countTrackPlayed = 0;
            await this.addActions(
                "listen",
                [list],
                countTrackActionsByUser => {
                    countTrackPlayed = countTrackActionsByUser;
                }
            );

            // if (countTrackPlayed >= 5) {
            //     let isTrackLimited = true;
            //     const alertMessage = "Preview limit maxed out";
            //     this.setState({
            //         isTrackLimited: isTrackLimited,
            //         trackLimitedMessage: alertMessage
            //     });
            //     this.resetTrackLimiting();
            //     return;
            // }
        }

        const newTracks = {
            ...audio,
            list: audio.list.map(elem => {
                if (elem.uiId === uiId) {
                    elem.versionId = versionId;
                }
                return elem;
            })
        };

        this.setState({
            audio: newTracks,
            choosenTrack: { track: { ...track, versionId }, isPlaying: true }
        });
    };

    pausePlaying = () => {
        console.log("pausePlaying");
        this.setState({
            choosenTrack: { ...this.state.choosenTrack, isPlaying: false }
        });
    };

    stopPlaying = () => {
        console.log("stopPlaying");
        this.setState({ choosenTrack: { track: null, isPlaying: false } });
    };

    addActions = async (type, list, callback) => {
        try {
            const { latitude, longitude } = await this.getLocation();
            const countTrackActionsByUser = await makeHttpRequest({
                method: "post",
                path: "/userActions",
                data: { type, list, longitude, latitude }
            });
            // console.log(callback);
            if (callback) await callback(countTrackActionsByUser);
        } catch (error) {
            console.log(error);
        }
    };

    fetchBanners = async () => {
        try {
            const {
                banners
            } = await makeHttpRequest({
                method: "get",
                path: "/banners/"
            });
            this.setState({ banners });
        } catch (error) {
            console.log(error);
        }
    };

    resetQuery = () => {
        this.setState({ ...FILTERS_INITIAL_STATE });
    };

    resetAudioFilters = cb => {
        this.setState(
            {
                keys: FILTERS_INITIAL_STATE.keys,
                bpm: FILTERS_INITIAL_STATE.bpm,
                versions: FILTERS_INITIAL_STATE.versions
            },
            cb
        );
    };

    resetSearchQuery = () => {
        this.setState({ searchQuery: "" });
    };

    render() {
        return (
            <ContentContext.Provider value={{ ...this.state, ...this.funcs }}>
                {this.props.children}
            </ContentContext.Provider>
        );
    }
}

export default withCommonDialogContext(function Container(props) {
    return (
        <AuthContext.Consumer>
            {value => <ContextContainer authValue={value} {...props} />}
        </AuthContext.Consumer>
    );
});

export { ContentContext };
